import * as React from "react";
import { Box } from "theme-ui";
import { useTransition, animated } from "@react-spring/web";

import Heading from "components/typography/Heading";
import Link from "components/Link";
import Modal from "components/Modal";
import PropTypes from "lib/propTypes";

const AnimatedBox = animated(Box);

function Navigation({ close, logo, logoIcon, navigation, visible }) {
  const itemTransitions = useTransition(visible ? navigation : [], {
    from: { opacity: 0, x: `10%` },
    enter: { opacity: 1, x: `0` },
    leave: { opacity: 0, x: `-10%` },
    trail: 100,
    keys: ({ _uid }) => _uid,
  });

  return (
    <Modal {...{ close, logo, logoIcon, visible }}>
      <Box
        as="ul"
        variant="lists.unstyled"
        sx={{ margin: `auto`, pb: [80, 100], textAlign: `center` }}
      >
        {itemTransitions((props, { _uid, hoverColor, link, title }) => (
          <AnimatedBox key={_uid} as="li" my="m" style={props}>
            <Link
              to={link}
              activeClassName="active"
              variant="links.default"
              sx={{
                textDecoration: `none`,
                "&:focus-visible, &:hover, &.active": {
                  color: hoverColor,
                },
              }}
            >
              <Heading as="span" variant="h2-bold">
                {title}
              </Heading>
            </Link>
          </AnimatedBox>
        ))}
      </Box>
    </Modal>
  );
}

Navigation.propTypes = {
  close: PropTypes.func.isRequired,
  logo: PropTypes.asset.isRequired,
  logoIcon: PropTypes.asset.isRequired,
  navigation: PropTypes.arrayOf(
    PropTypes.shape({
      _uid: PropTypes.string,
      hoverColor: PropTypes.string,
      link: PropTypes.link,
      title: PropTypes.string,
    })
  ).isRequired,
  visible: PropTypes.bool,
};

Navigation.defaultProps = {
  visible: true,
};

export default Navigation;
